<template>
    <div>
        <h1>Shipping Manifest</h1>
        <div class="panel panel-default">
            <div class="panel-heading"></div>
            <div class=" panel-body">
                <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Ship Date</label>
                    <DatePicker v-model='optionFields.shipDate.val' :input-attributes="{class: 'form-control'}" />
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>CDI Location</label>
                    <select v-model="optionFields.cdiLocation.val" class="form-control" >
                        <option value='0'>All</option>
                        <option v-for="[id, locations] in cache.locations.CDI_LOCATIONS" v-bind:key="id" :value="id" >{{ locations }}</option>
                    </select>
                </div>
                <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                    <label>Shipper</label>
                    <Typeahead cdi_type="shipping_co" v-bind:ID.sync="optionFields.shipperId.val" />
                </div>

                <div class="col-md-3 col-sm-4 col-xs-6 form_grid">
                    <input type="button" class='btn btn-default' value="Email" @click="getEmails" >
                </div>
            </div>
        </div>
        <div v-show="emailResponses">
            <div style="white-space: pre" v-html="emailResponses"></div>
        </div>
        <Results />

        <Modal heading="Shipping Manifest Emails" v-if="showModal" @close="showModal = false">
            <template v-slot:body>
                <p>{{ modalText }}</p>
                <div class="form-group" v-if='manifestEmails.length'>
                    <span v-for='(email) in manifestEmails' v-bind:key='email.shipping_manifest_email_id'>{{ email.email }}<br /></span>
                </div>
                <div v-else>
                    <label>Email:</label>
                    <textarea v-model="modalNotes" class="dialog-textarea form-control" placeholder="Add email addresses here."></textarea>
                </div>
            </template>
            <template v-slot:footer>
                <button class="btn btn-primary" @click="emailPdfConfirm">Yes</button>
                <button class="btn btn-default" @click="showModal = false">No</button>
            </template>
        </Modal>

    </div>
</template>

<script>
import { store } from '@/store/Report.store';
import cdiLocationCache from '@/cache/cdiLocation.cache';
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import DatePicker from "@/components/utils/DatePicker";
import Results from "@/components/reports/utils/Results";
import Modal from '@/components/utils/Modal';
import helpers from "@/store/helpers";

export default {
    name: "ship_manifest",
    components: { Results, Typeahead , DatePicker, Modal },
    data() {
        return {
            state: store.state,
            optionFields: {
                shipDate: {val: new Date().toLocaleDateString(), qsField: '[dates][actual_ship]'},
                cdiLocation: {val: 0, qsField: '[numbers][cdi_location_id]'},
                shipperId: {val: 0, qsField: '[numbers][t.shipping_co_id]'},
                email: {val: '', qsField: '[email]'},
                orderby: {val: '', qsField: '[orderby]'},
                direction: {val: '', qsField: '[direction]'},
                json: {val: 1, qsField: '[json]'}
            },
            cache: {
                locations: cdiLocationCache
            },
            modalText: '',
            manifestEmails: [],
            showModal: false,
            modalNotes: '',
            emailResponses: '',
        }
    },
    methods: {
        runReport: function() {
            store.runReport();
        },
        emailPdfConfirm: function() {
            let emailsArr = [];
            let emailsStr = '';
            if (this.manifestEmails.length) {
                for (let i = 0; i < this.manifestEmails.length; i++) {
                    emailsArr.push(this.manifestEmails[i].email);
                }
                emailsStr = emailsArr.join(',');
            } else {
                emailsArr = this.modalNotes.split(',');
                emailsStr = this.modalNotes;
            }

            this.modalNotes = '';
            this.showModal = false;

            for (let i = 0; i < emailsArr.length; i++) {
                if (!this.$appStore.validateEmail(emailsArr[i])) {
                    this.$appStore.errorBox('Invalid email address entered.');
                    return;
                }
            }

            this.manifestEmails = [];

            this.optionFields.email.val = emailsStr;
            store.customReport('ship_manifest', 'email_pdf', helpers.getQSPairs(this.optionFields)).then( (res) => {
                if (res) {
                    this.emailResponses = res;
                }
            });
        },
        getEmails: function() {
            if (this.optionFields.shipperId.val) {
                store.customAPI(`shipper/get_manifest_emails_json/${ this.optionFields.shipperId.val }`,{'params[shipping_co_id]': this.optionFields.shipperId.val, 'params[cdi_location_id]': this.optionFields.cdiLocation.val}).then( (emails) => {
                    this.manifestEmails = emails.manifest_emails;
                    if (this.manifestEmails.length) {
                        this.modalText = 'These emails will be notified:';
                        this.showModal = true;
                    } else {
                        this.modalText = 'No manifest email addresses found.';
                        this.showModal = true
                    }
                });
            } else {
                this.$appStore.errorBox('Enter a name and select a shipper first.');
            }
        }
    },
    created() {
        this.$appStore.setTitle(['Shipping Manifest']);
        this.$set(store.state.results, this.$options.name, '');
        store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
    },
}
</script>

<style scoped>

</style>